<template>
  <div class="occurrence">
    <div v-if="loading || !occurrence">
      <b-container fluid="xxl">
        <b-row class="justify-content-md-center">
            <b-col class="mt-5">
              <div class="spinner-border m-5" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-else>
      <b-container fluid="xxl">
        <b-row>
          <b-col class="pl-0">
            <PageTitle v-bind:title="occurrence.display" :pretitle="'Occurrence details'" />
          </b-col>
          <b-col>
            <div class="mt-5 text-right">
              <span v-if="editMode && config.editable">
                <button @click="saveEdit" class="btn-legam-xs mb-2 btn-primary">
                  <b-icon icon="lock-fill"></b-icon>
                  Save changes
                </button>
                <button @click="cancelEdit" class="btn-legam-xs mb-2">
                  Cancel
                </button>
                <button @click="deleteItem" v-if="$store.getters.checkRole('legam.dict.occurrence.delete')" class="btn-legam-xs mb-2 btn-danger">
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
                <button @click="duplicateItem" v-if="$store.getters.checkRole('legam.dict.occurrence.add')" class="btn-legam-xs mb-2 btn-info">
                  <b-icon icon="layers-fill"></b-icon>
                  Duplicate
                </button>
              </span>
              <span v-else>
                <button @click="editMode = true" v-if="$store.getters.checkRole('legam.dict.occurrence.modify')" class="btn-legam-xs mb-2">
                  <b-icon icon="unlock-fill"></b-icon>
                  Unlock editing
                </button>
                <button @click="duplicateItem" v-if="$store.getters.checkRole('legam.dict.occurrence.add')" class="btn-legam-xs mb-2 btn-info">
                  <b-icon icon="layers-fill"></b-icon>
                  Duplicate
                </button>
                <button @click="deleteItem" v-if="!$store.getters.checkRole('legam.dict.occurrence.modify') && $store.getters.checkRole('legam.dict.occurrence.delete')" class="btn-legam-xs mb-2 btn-danger">
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
            </div>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col>
            <article>
              <b-row class="mt-2">
                <b-col>
                  <h6>Comment</h6>
                  <b-form-textarea
                    id="comment"
                    placeholder="Comment"
                    v-model="occurrence.comment"
                    rows="3"
                    max-rows="6"
                    v-if="editMode"
                  ></b-form-textarea>
                  <span v-else v-html="occurrence.comment" class="white-space-pre-line"></span>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>
                  <h6>Public Comment Sense</h6>
                  <b-form-textarea
                    id="public-comment-sense"
                    placeholder="Public Comment Sense"
                    v-model="occurrence.publicCommentSense"
                    rows="3"
                    max-rows="6"
                    v-if="editMode"
                  ></b-form-textarea>
                  <span v-else v-html="occurrence.publicCommentSense" class="white-space-pre-line"></span>
                </b-col>
                <b-col>
                  <h6>Public Comment Spelling</h6>
                  <b-form-textarea
                    id="public-comment-spelling"
                    placeholder="Public Comment Spelling"
                    v-model="occurrence.publicCommentSpelling"
                    rows="3"
                    max-rows="6"
                    v-if="editMode"
                  ></b-form-textarea>
                  <span v-else v-html="occurrence.publicCommentSpelling" class="white-space-pre-line"></span>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>
                  <h6>Suppressed Sense Dictionaries</h6>
                  <DictionarySelect
                    v-if="editMode"
                    :selected="occurrence.suppressedSenseDictionaries.map(dictionary => dictionary.id)"
                    @select="setSuppressedSenses"
                    :multiple="true"
                  />
                  <span v-else v-html="getSuppressedList(occurrence.suppressedSenseDictionaries)" class="white-space-pre-line"></span>
                </b-col>
                <b-col>
                  <h6>Suppressed Spelling Dictionaries</h6>
                  <DictionarySelect
                    v-if="editMode" :selected="occurrence.suppressedSpellingDictionaries.map(dictionary => dictionary.id)"
                    @select="setSuppressedSpellings"
                    :multiple="true"
                  />
                  <span v-else v-html="getSuppressedList(occurrence.suppressedSpellingDictionaries)" class="white-space-pre-line"></span>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col>
                  <h6>Suppressed Context Dictionaries</h6>
                  <DictionarySelect
                    v-if="editMode"
                    :selected="occurrence.suppressedContextDictionaries.map(dictionary => dictionary.id)"
                    @select="setSuppressedContexts"
                    :multiple="true"
                  />
                  <span v-else v-html="getSuppressedList(occurrence.suppressedContextDictionaries)" class="white-space-pre-line"></span>
                </b-col>
                <b-col>
                </b-col>
              </b-row>

              <b-button @click="showUnstructData = !showUnstructData" size="sm" class="mb-2 mt-4" v-if="occurrence.unstruct && config.editable">
                <span v-if="showUnstructData">Hide unstruct data</span>
                <span v-else>Show unstruct data</span>
              </b-button>

              <div v-show="showUnstructData" v-if="occurrence.unstruct">
                <h6 class="with-line mt-4">Unstruct</h6>
                <b-row cols="2">
                  <b-col v-for="key in Object.keys(filteredUnstructData)" :key="key">
                    <h6>{{ key.replace(/_/ig, ' ') }}</h6>
                    <span v-html="occurrence.unstruct[key]" class="white-space-pre-line"></span>
                  </b-col>
                </b-row>
                <!-- <b-row class="mt-2">
                  <b-col>
                    <h6>Siglum</h6>
                    <span v-html="occurrence.unstruct.siglum" class="white-space-pre-line"></span>
                  </b-col>
                  <b-col>
                    <h6>Siglum Legacy</h6>
                    <span v-html="occurrence.unstruct.siglum_legacy" class="white-space-pre-line"></span>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col>
                    <h6>Origin</h6>
                    <span v-html="occurrence.unstruct.origin" class="white-space-pre-line"></span>
                  </b-col>
                  <b-col>
                    <h6>Siglum Type</h6>
                    <span v-html="occurrence.unstruct.siglumType" class="white-space-pre-line"></span>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col>
                    <h6>Siglum Date</h6>
                    <span v-html="occurrence.unstruct.siglumDate" class="white-space-pre-line"></span>
                  </b-col>
                  <b-col>
                    <h6>Siglum Date Num</h6>
                    <span v-html="occurrence.unstruct.siglumNumDate" class="white-space-pre-line"></span>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col>
                    <h6>Siglum Scripta String</h6>
                    <span v-html="occurrence.unstruct.siglumScriptaString" class="white-space-pre-line"></span>
                  </b-col>
                  <b-col>
                    <h6>Old ID Type</h6>
                    <span v-html="occurrence.unstruct.old_id_type" class="white-space-pre-line"></span>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col>
                    <h6>Text Type</h6>
                    <span v-html="occurrence.unstruct.textType" class="white-space-pre-line"></span>
                  </b-col>
                  <b-col>
                    <h6>Morphology</h6>
                    <span v-html="occurrence.unstruct.morphology" class="white-space-pre-line"></span>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col>
                    <h6>Context Left</h6>
                    <span v-html="occurrence.unstruct.contextLeft" class="white-space-pre-line"></span>
                  </b-col>
                  <b-col>
                    <h6>Context Right</h6>
                    <span v-html="occurrence.unstruct.contextRight" class="white-space-pre-line"></span>
                  </b-col>
                </b-row> -->
              </div>

              <span v-if="occurrence.externalEvidence">
                <h6 class="with-line mt-4">External Evidence</h6>
                <b-row class="mt-2">
                  <b-col>
                    <h6>Edition Sigle Legam</h6>
                    <EditionSelect v-if="editMode" :edition="occurrence.externalEvidence.edition" @select="selectEdition" />
                    <span v-else>
                      <router-link
                        v-if="occurrence.externalEvidence.editionId"
                        :to="{name: 'EditionDetails', params: {
                          slug: occurrence.externalEvidence.edition.sigleLegamNormalized,
                          id: occurrence.externalEvidence.edition.id
                        }}"
                        v-html="occurrence.externalEvidence.edition.sigleLegam"
                      ></router-link>
                    </span>
                  </b-col>
                  <b-col>
                    <h6>Manuscript Code</h6>
                    <ManuscriptSelect v-if="editMode" :manuscript="occurrence.externalEvidence.manuscript" @select="selectManuscript" />
                    <span v-else>
                      <router-link
                        v-if="occurrence.externalEvidence.manuscriptId"
                        :to="{name: 'ManuscriptDetails', params: {id: occurrence.externalEvidence.manuscriptId}}"
                        v-html="occurrence.externalEvidence.manuscript.codeRepcrit"
                      ></router-link>
                    </span>
                  </b-col>
                </b-row>

                <b-row class="mt-2">
                  <b-col>
                    <h6>Study Sigle Legam</h6>
                    <StudySelect v-if="editMode" :study="occurrence.externalEvidence.study" @select="selectStudy" />
                    <span v-else>
                      <router-link
                        v-if="occurrence.externalEvidence.studyId"
                        :to="{name: 'StudyDetails', params: {
                          slug: occurrence.externalEvidence.study.sigleLegamNormalized,
                          id: occurrence.externalEvidence.study.id
                        }}"
                        v-html="occurrence.externalEvidence.study.sigleLegam"
                      ></router-link>
                    </span>
                  </b-col>
                  <b-col>
                    <h6>Location</h6>
                    <LocationSelect v-if="editMode" :location="occurrence.externalEvidence.location" @select="selectLocation" />
                    <span v-else>
                      <span v-if="occurrence.externalEvidence.location" v-html="occurrence.externalEvidence.location.display"></span>
                    </span>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col>
                    <h6>Context</h6>
                    <b-form-textarea
                      id="ee-context"
                      placeholder="Context"
                      v-model="occurrence.externalEvidence.context"
                      rows="3"
                      max-rows="6"
                      v-if="editMode"
                    ></b-form-textarea>
                    <span v-else v-html="occurrence.externalEvidence.context" class="white-space-pre-line"></span>
                  </b-col>
                  <b-col>
                    <h6>Position</h6>
                    <b-form-input
                      id="ee-position"
                      v-model="occurrence.externalEvidence.position"
                      placeholder="Position"
                      v-if="editMode"
                    ></b-form-input>
                    <span v-else v-html="occurrence.externalEvidence.position"></span>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col>
                    <h6>Surface Form</h6>
                    <b-form-input
                      id="ee-surface-form"
                      v-model="occurrence.externalEvidence.surfaceForm"
                      placeholder="Surface Form"
                      v-if="editMode"
                    ></b-form-input>
                    <span v-else v-html="occurrence.externalEvidence.surfaceForm"></span>
                  </b-col>
                  <b-col>
                    <h6>Part of Speech</h6>
                    <b-form-input
                      id="ee-part-of-speech"
                      v-model="occurrence.externalEvidence.partOfSpeech"
                      placeholder="Part of Speech"
                      v-if="editMode"
                    ></b-form-input>
                    <span v-else v-html="occurrence.externalEvidence.partOfSpeech"></span>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col>
                    <h6>Date Spec Manuscript</h6>
                    <b-form-input
                      id="ee-date-spec-manuscript"
                      v-model="occurrence.externalEvidence.dateSpecManuscript"
                      placeholder="Date Spec Manuscript"
                      v-if="editMode"
                    ></b-form-input>
                    <span v-else v-html="occurrence.externalEvidence.dateSpecManuscript"></span>
                  </b-col>
                  <b-col>
                    <h6>Date Num Manuscript</h6>
                    <b-form-input
                      id="ee-date-num-manuscript"
                      v-model="occurrence.externalEvidence.dateNumManuscript"
                      placeholder="Date Num Manuscript"
                      v-if="editMode"
                      type="date"
                    ></b-form-input>
                    <span v-else v-html="occurrence.externalEvidence.dateNumManuscript"></span>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col>
                    <h6>Date Spec Text</h6>
                    <b-form-input
                      id="ee-date-spec-text"
                      v-model="occurrence.externalEvidence.dateSpecText"
                      placeholder="Date Spec Text"
                      v-if="editMode"
                    ></b-form-input>
                    <span v-else v-html="occurrence.externalEvidence.dateSpecText"></span>
                  </b-col>
                  <b-col>
                    <h6>Date Num Text</h6>
                    <b-form-input
                      id="ee-date-num-text"
                      v-model="occurrence.externalEvidence.dateNumText"
                      placeholder="Date Num Text"
                      v-if="editMode"
                      type="date"
                    ></b-form-input>
                    <span v-else v-html="occurrence.externalEvidence.dateNumText"></span>
                  </b-col>
                </b-row>
              </span>

              <span v-if="occurrence.token">
                <h6 class="with-line mt-4">Token</h6>
                <b-row class="mt-2">
                  <b-col>
                    <h6>Idx</h6>
                    <span v-html="occurrence.token.idx"></span>
                  </b-col>
                  <b-col>
                    <h6>Token Type</h6>
                    <span v-if="occurrence.token.tokentype" v-html="occurrence.token.tokentype.name"></span>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col>
                    <h6>Length</h6>
                    <span v-html="occurrence.token.length"></span>
                  </b-col>
                  <b-col>
                    <h6>Document</h6>
                    <span v-if="occurrence.token.document && occurrence.token.document.edition" v-html="occurrence.token.document.edition.sigleLegam"></span>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col>
                    <h6>Original</h6>
                    <span v-if="occurrence.token.original" v-html="occurrence.token.original.type"></span>
                  </b-col>
                  <b-col>
                    <h6>Division</h6>
                    <span v-html="occurrence.token.division"></span>
                  </b-col>
                </b-row>
              </span>
            </article>
          </b-col>
        </b-row>
        <hr class="mt-4">
        <b-row class="justify-content-md-center mt-4">
          <b-col>
            <h5>Sense</h5>
            <router-link
              v-if="occurrence.sense"
              :to="{name: 'Sense', params: {id: occurrence.sense.id}}"
              v-html="occurrence.sense.definition"
            ></router-link>
          </b-col>
          <b-col>
            <h5>Protospelling</h5>
            <router-link
              v-if="occurrence.protospelling"
              :to="{name: 'ProtospellingDetails', params: {id: occurrence.protospelling.id}}"
              v-html="occurrence.protospelling.spelling"
            ></router-link>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center mt-4">
          <b-col>
            <h6>Lemma</h6>
            <LemmaSelect
              v-if="editMode"
              :lemma="occurrence.occurrenceLemma ? occurrence.occurrenceLemma.lemma : null"
              @select="selectLemma"
            />
            <span v-else-if="occurrence.occurrenceLemma && occurrence.occurrenceLemma.lemmaId">
              <router-link
                :to="{name: 'Lemma', params: {id: occurrence.occurrenceLemma.lemma.id}}" target="_blank">
                <span v-html="occurrence.occurrenceLemma.lemma.display"></span>
              </router-link>
            </span>
          </b-col>
          <b-col>
          </b-col>
        </b-row>
        <!-- <hr class="mt-4">
        <div class="accordion mt-4" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.accordion-1 class="text-left">
                Lemmas <small>({{ occurrence.lemmas.length }} entries)</small>
                <span class="when-open float-right">-</span>
                <span class="when-closed float-right">+</span>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-1" accordion="my-accordion-1" role="tabpanel">
              <b-card-body>
                <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-lemma>
                  <b-icon icon="plus"></b-icon>
                  Add
                </span>
                <b-modal id="modal-add-lemma" title="Add lemma" ok-only ok-variant="secondary" size="lg">
                  <LemmaAutocomplete @select="addLemma" />
                </b-modal>
                <LemmaTable
                  :showTotal="false"
                  :lemmas="occurrence.lemmas.map(lemma => lemma.lemma)"
                  @delete="deleteLemma"
                  :editMode="editMode"
                  v-if="occurrence.lemmas.length"
                />
                <span v-else>No data</span>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div> -->
        <hr class="mt-4">
        <div class="mt-4" v-if="config.editable">
          <h6>Logs</h6>
          <ChangeLogsTable :objectType="occurrence._occurrenceType" :objectId="occurrence.id" :key="'logs' + index" />
        </div>
      </b-container>
    </div>
    <b-modal id="modal-duplicate-occurrence" title="Duplicate occurrence" ok-title="Save" @ok="createOccurrence" size="lg">
      <OccurrenceNew :isLemmaRequired="true" ref="newOccurrenceForm" :type="'occurrence'" :occurrence="{...this.occurrence}" />
    </b-modal>
  </div>
</template>

<style scoped>
  h6 {
    margin-bottom: 2px;
    margin-top: 1rem;
  }
</style>

<script>
  import { mapState } from 'vuex'
  import router from '@/router'
  import PageTitle from '@/components/PageTitle.vue'
  import EditionSelect from '@/components/Bibliography/EditionSelect.vue'
  import ManuscriptSelect from '@/components/Bibliography/ManuscriptSelect.vue'
  import DictionarySelect from '@/components/DictionarySelect.vue'
  import ChangeLogsTable from '@/components/ChangeLogsTable.vue'
  import StudySelect from '@/components/Bibliography/StudySelect.vue'
  import LocationSelect from '@/components/Main/LocationSelect.vue'
  import LemmaSelect from '@/components/Lemma/LemmaSelect.vue'
  import HelpStore from '@/store/helpstore.js'
  import OccurrenceNew from '@/components/OccurrenceNew.vue'
  import config from '@/config.js'
  // import LemmaTable from '@/components/Lemma/Table.vue'
  // import LemmaAutocomplete from '@/components/Lemma/Autocomplete.vue'

  export default {
    name: 'OccurrenceDetails',
    data(){
      return {
        config: config,
        occurrenceId: this.$route.params.id,
        loading: true,
        editMode: false,
        showUnstructData: false,
        index: 0
      }
    },
    components: {
      PageTitle,
      EditionSelect,
      ManuscriptSelect,
      DictionarySelect,
      ChangeLogsTable,
      StudySelect,
      LocationSelect,
      LemmaSelect,
      OccurrenceNew,
      // LemmaTable,
      // LemmaAutocomplete,
    },
    computed: {
      ...mapState({
        occurrence: state => state.occurrence.occurrence
      }),
      filteredUnstructData(){
        let retval = {}
        Object.keys(this.occurrence.unstruct).forEach(key => {
          if (key != 'unused_attributes'){
            retval[key] = this.occurrence.unstruct[key]
          }
        })
        return retval
      }
    },
    watch: {
      'occurrence'() {
        this.loading = false
      }
    },
    mounted(){
      this.$store.dispatch('loadOccurrence', this.$route.params.id)
    },
    methods: {
      getOccurrenceId(){
        let occurrenceId = null
        let containeroccurrenceId = null
        if (this.occurrence._occurrenceType == "containeroccurrence"){
          containeroccurrenceId = this.occurrence.id
        }
        else {
          occurrenceId = this.occurrence.id
        }
        return { occurrenceId, containeroccurrenceId }
      },
      cancelEdit(){
        this.editMode = false
        this.$store.dispatch('loadOccurrence', this.occurrenceId)
      },
      duplicateItem(){
        this.$bvModal.show('modal-duplicate-occurrence')
      },
      createOccurrence(){
        this.$store.dispatch('createOccurrence', HelpStore.item).then((response) => {
          router.push(`/occurrence/${response.data.id}`)
          this.$store.dispatch('loadOccurrence', response.data.id)
        })
      },
      selectLemma(lemma){
        let { occurrenceId, containeroccurrenceId } = this.getOccurrenceId()
        this.occurrence.occurrenceLemma = null
        if (lemma){
          this.occurrence.occurrenceLemma = {
            "lemmaId": lemma.id,
            "lemma": lemma,
            "occurrenceId": occurrenceId,
            "containeroccurrenceId": containeroccurrenceId
          }
        }
      },
      saveEdit(){
        this.editMode = false
        this.$store.dispatch('saveOccurrence', this.occurrence).then(() => {
          this.$store.dispatch('addNotification', {
            type: 'success',
              text: `Changes saved`
          })
        })
      },
      deleteItem(){
        this.$bvModal.msgBoxConfirm('Are you sure?').then(value => {
          if (value){
            this.$store.dispatch('deleteOccurrence', this.occurrence).then(() => {
              router.push('/occurrences/internal')
            })
          }
        })
      },
      selectEdition(edition){
        this.occurrence.externalEvidence.editionId = null
        this.occurrence.externalEvidence.edition = null
        if (edition) {
          this.occurrence.externalEvidence.editionId = edition.id
          this.occurrence.externalEvidence.edition = edition
        }
      },
      selectStudy(study){
        this.occurrence.externalEvidence.studyId = null
        this.occurrence.externalEvidence.study = null
        if (study) {
          this.occurrence.externalEvidence.studyId = study.id
          this.occurrence.externalEvidence.study = study
        }
      },
      selectLocation(location){
        this.occurrence.externalEvidence.locationId = null
        this.occurrence.externalEvidence.location = null
        if (location) {
          this.occurrence.externalEvidence.locationId = location.id
          this.occurrence.externalEvidence.location = location
        }
      },
      selectManuscript(manuscript){
        this.occurrence.externalEvidence.manuscriptId = null
        this.occurrence.externalEvidence.manuscript = null
        if (manuscript){
          this.occurrence.externalEvidence.manuscriptId = manuscript.id
          this.occurrence.externalEvidence.manuscript = manuscript
        }
      },
      selectTokentype(tokentype){
        this.occurrence.token.tokentype = tokentype
        this.occurrence.token.tokentypeId = tokentype.id
      },
      setSuppressedSenses(dictionaries){
        this.occurrence.suppressedSenseDictionaries = dictionaries
      },
      setSuppressedSpellings(dictionaries){
        this.occurrence.suppressedSpellingDictionaries = dictionaries
      },
      setSuppressedContexts(dictionaries){
        this.occurrence.suppressedContextDictionaries = dictionaries
      },
      getSuppressedList(suppressedDictionaries){
        return suppressedDictionaries.map(dictionary => {
          let langCode = dictionary.languageCode ? ` (${dictionary.languageCode})` : ''
          return `${dictionary.acronym}${langCode}`
        }).join(', ')
      },
      addLemma(lemma){
        this.$bvModal.hide('modal-add-lemma')
        let { occurrenceId, containeroccurrenceId } = this.getOccurrenceId()
        this.occurrence.occurrenceLemma = {
          "lemmaId": lemma.id,
          "lemma": lemma,
          "occurrenceId": occurrenceId,
          "containeroccurrenceId": containeroccurrenceId
        }
      },
      // deleteLemma(lemmaId){
      deleteLemma(){
        // this.occurrence.lemmas = this.occurrence.lemmas.filter(lemma => lemma.lemmaId != lemmaId)
        this.occurrence.occurrenceLemma = null
      },
    }
  }
</script>
